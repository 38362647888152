import { lazy, Suspense, useContext, useState } from 'react';
import { tokens } from '../../theme';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import MyContext from '../../utils/MyContext';
import Sidebar from './Sidebar';
import { Box, Stack } from '@mui/material';
import Topbar from './Topbar';
import SkeletonLoader from '../SkeletonLoader';
import { useTheme } from '@emotion/react';
import { Toaster } from 'react-hot-toast';
import withAuth from '../../utils/withAuth';
import SmallLoader from '../SmallLoader';

const Verify = lazy(() => import('../../pages/Verify'));
const Register = lazy(() => import('../../pages/Register/register'));
const Login = lazy(() => import('../../pages/login/login'));
const EditStaff = lazy(() => import('../../pages/Admin/EditStaff'));
const ProfilePage = lazy(() => import('../../pages/ProfilePage'));
const DoctorDashboard = lazy(() => import('../../pages/Doctor/DoctorDashboard'));
const DocPatients = lazy(() => import('../../pages/Doctor/DocPatients'));
const DocSchedule = lazy(() => import('../../pages/Doctor/DocSchedule'));
const PaitentHistory = lazy(() => import('../../pages/Doctor/PatientHistory'));
const DiagnosisPage = lazy(() => import('../../pages/Doctor/DiagnosisPage'));
const NotFound = lazy(() => import('../../pages/NotFound'));
const ReceptionistDashboard = lazy(() => import('../../pages/receptionist/ReceptionistDashboard'));
const MainHolder = lazy(() => import('./MainHolder'));
const AddNewPatient = lazy(() => import('../../pages/receptionist/AddNewPatient'));
const AssignPatient = lazy(() => import('../../pages/receptionist/AssignPatient'));
const SchedulePatient = lazy(() => import('../../pages/receptionist/SchedulePatient'));
const Patients = lazy(() => import('../../pages/receptionist/Patients'));
const QueuePatient = lazy(() => import('../../pages/receptionist/QueuePatient'));
const PatientPayment = lazy(() => import('../../pages/receptionist/PatientPayment'));
const AdminDashboard = lazy(() => import('../../pages/Admin/AdminDashboard'));
const DoctorsList = lazy(() => import('../../pages/Admin/DoctorsList'));
const ReceptionistList = lazy(() => import('../../pages/Admin/ReceptionistList'));
const SettingsPage = lazy(() => import('../../pages/Admin/SettingsPage'));
const AddStaff = lazy(() => import('../../pages/Admin/AddStaff'));
const StatisticsPage = lazy(() => import('../../pages/Admin/StatisticsPage'));

function Layout() {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const [isSidebar, setIsSidebar] = useState(true);
	const location = useLocation();
	const { user } = useContext(MyContext);

	// const navigate = useNavigate();

	const shouldRenderSidebar =
		!location.pathname.startsWith('/register') &&
		!location.pathname.startsWith('/login') &&
		!location.pathname.startsWith('/verify');

	let dashboard = null;

	switch (user.r_data) {
		case 'admin':
			dashboard = '/admin';
			break;
		case 'receptionist':
			dashboard = '/reception';
			break;
		case 'doctor':
			dashboard = '/doctor';
			break;
		default:
			dashboard = '/login';
	}

	const appRoutes = [
		{
			element: (
				<Route
					key="/register-path"
					path="/register"
					element={
						<Suspense fallback={<SmallLoader />}>
							<Register />
						</Suspense>
					}
				/>
			),
			requiredRole: ['none'],
		},
		{
			element: (
				<Route
					key="/verify-path"
					path="/verify/:package_id"
					element={
						<Suspense fallback={<SmallLoader />}>
							<Verify />
						</Suspense>
					}
				/>
			),
			requiredRole: ['none'],
		},
		{
			element: (
				<Route
					key="/login-path"
					path="/login"
					element={
						<Suspense fallback={<SmallLoader />}>
							<Login />
						</Suspense>
					}
				/>
			),
			requiredRole: ['none'],
		},
		{
			element: <Route exact key="/redirect-path" path="/" element={<Navigate to={dashboard} />} />,
			requiredRole: ['none'],
		},

		{
			element: (
				<Route
					key="/profile-path"
					path="/profile"
					element={
						<Suspense fallback={<SkeletonLoader />}>
							<ProfilePage />
						</Suspense>
					}
				/>
			),
			requiredRole: ['doctor', 'admin', 'receptionist'],
		},
		{
			element: (
				<Route
					key="/admin-path"
					path="/admin"
					element={
						<Suspense fallback={<SkeletonLoader />}>
							<MainHolder />
						</Suspense>
					}
				>
					<Route index element={<AdminDashboard />} />
					<Route path="doctors" element={<DoctorsList />} />
					<Route path="receptionists" element={<ReceptionistList />} />
					<Route path="statistics" element={<StatisticsPage />} />
					<Route path="settings" element={<SettingsPage />} />
					<Route path="edit-staff/:staff_id" element={<EditStaff />} />
					<Route path="add-staff" element={<AddStaff />} />
				</Route>
			),
			requiredRole: ['admin'],
		},
		{
			element: (
				<Route
					key="/reception-path"
					path="/reception"
					element={
						<Suspense fallback={<SkeletonLoader />}>
							<MainHolder />
						</Suspense>
					}
				>
					<Route index element={<ReceptionistDashboard />} />
					<Route path="patients" element={<Patients />} />
					<Route path="add-patient" element={<AddNewPatient />} />
					<Route path="assign/:patient_id" element={<AssignPatient />} />
					<Route path="schedule/:patient_id" element={<SchedulePatient />} />
					<Route path="queue" element={<QueuePatient />} />
					<Route path="payment/:patient_id" element={<PatientPayment />} />
					<Route path="payment" element={<PatientPayment />} />
				</Route>
			),
			requiredRole: ['receptionist'],
		},
		{
			element: (
				<Route
					key="/doctor-path"
					path="/doctor"
					element={
						<Suspense fallback={<SkeletonLoader />}>
							<MainHolder />
						</Suspense>
					}
				>
					<Route index element={<DoctorDashboard />} />
					<Route path="patients" element={<DocPatients />} />
					<Route path="schedule/:patient_id" element={<DocSchedule />} />
					<Route path="schedule" element={<DocSchedule />} />
					<Route path="diagnosis/:patient_id" element={<DiagnosisPage />} />
					<Route path="history" element={<PaitentHistory />} />
				</Route>
			),
			requiredRole: ['doctor'],
		},
	];

	return (
		<>
			{shouldRenderSidebar && <Sidebar isSidebar={isSidebar} />}
			<Stack component="main" direction="column" className="content">
				{shouldRenderSidebar && <Topbar setIsSidebar={setIsSidebar} />}
				<Box sx={{ height: 'fit-content', overflow: 'auto' }}>
					<Routes>
						{appRoutes.map((route) => {
							if (route.requiredRole.includes(user.r_data) || route.requiredRole.includes('none')) {
								return route.element;
							}
							return null;
						})}
						<Route path="*" element={<NotFound />} />
					</Routes>
				</Box>
				<Toaster
					position="top-center"
					reverseOrder={false}
					gutter={8}
					containerStyle={{}}
					toastOptions={{
						duration: 3000,
						style: {
							borderRadius: '20px',
							minWidth: '150px',
						},
						success: {
							style: {
								background: colors.greenAccent[800],
								color: colors.black,
							},
						},
						error: {
							style: {
								background: colors.redAccent[800],
								color: colors.black,
							},
						},
					}}
				/>
			</Stack>
		</>
	);
}

export default withAuth(Layout);
